import { render, staticRenderFns } from "./as.vue?vue&type=template&id=95cc3e8e&scoped=true&"
import script from "./as.vue?vue&type=script&lang=js&"
export * from "./as.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95cc3e8e",
  null
  
)

export default component.exports